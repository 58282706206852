var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tag-input" },
    [
      _vm._l(_vm.tags, function (tag, index) {
        return _c("div", { key: tag + index, staticClass: "tag-input__tag" }, [
          _vm._v("\n    " + _vm._s(tag) + "\n    "),
          _c(
            "button",
            {
              attrs: { type: "button", "aria-label": _vm.$t("generic.close") },
              on: {
                click: function ($event) {
                  return _vm.removeTag(index)
                },
              },
            },
            [
              _c("span", {
                staticClass: "close",
                attrs: { "aria-hidden": "true" },
              }),
            ]
          ),
        ])
      }),
      _c("input", {
        staticClass: "tag-input__text",
        style: _vm.inputWidth,
        attrs: {
          type: "text",
          placeholder: _vm.tags.length == 0 ? _vm.placeholder : null,
        },
        on: {
          input: function ($event) {
            return _vm.updateWidth($event)
          },
          keydown: [
            function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.addTag.apply(null, arguments)
            },
            function ($event) {
              if (!$event.type.indexOf("key") && $event.keyCode !== 9)
                return null
              return _vm.addTag.apply(null, arguments)
            },
            function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "delete", [8, 46], $event.key, [
                  "Backspace",
                  "Delete",
                  "Del",
                ])
              )
                return null
              return _vm.removeLastTag.apply(null, arguments)
            },
          ],
          blur: _vm.addTag,
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }