<template>
  <div class='tag-input'>
    <div v-for='(tag, index) in tags' :key='tag+index' class='tag-input__tag'>
      {{ tag }}
      <button type="button" :aria-label="$t('generic.close')" @click='removeTag(index)'>
        <span aria-hidden="true" class="close"></span>
      </button>
    </div>
    <input
      type="text"
      :placeholder="tags.length == 0 ? placeholder : null" 
      class="tag-input__text"
      :style="inputWidth"
      @input="updateWidth($event)"
      @keydown.enter="addTag"
      @keydown.9="addTag"
      @keydown.delete="removeLastTag"
      @blur="addTag"
    />
  </div>
</template>
<script>
export default {
  model:{
    prop: 'tags',
    event: 'change'
  },
  props: {
    tags: {
      type: Array || null,
      default() {return []}
    },
    beforeAdding: {
      type: Function,
      default: null,
      required: false
    },
    createTag: {
      type: Function,
      default: null,
      required: false
    },
    placeholder: String
  },
  data () {
    return {
      inputWidth:{},
      // :create-tag="addTag"
      //     :before-adding="validateTag"
      //     :confirm-keys="['Enter','Tab','+']"
      //     :on-paste-separators="['+']"
      //     :allow-duplicates="true"
      //     :placeholder="snowsafetyLengths.length === 0 ? 'Ange längd i meter och klicka Enter.' : null">
    }
  },
  computed: {

  },
  methods: {
    updateWidth(event) {
      let length = event.target.value.length || 1
      this.inputWidth = {width: length > 0 ? length+'ch' : 1+'ch'}
    },
    addTag (event) {
      event.preventDefault()
      let tag = event.target.value
      
      // User parent components validation
      let validation = true
      if (this.beforeAdding) validation = this.beforeAdding(tag)
      if (!validation) {
        this.$emit('validationError', this.$t('roof.error.invalid_input'))
      } else {
        this.$emit('validationError', '')
      }

      // Parent compnents valuefunction
      let tagValue = tag.trim()
      if (this.createTag) {
        tagValue =  this.createTag(tag)
      }
      
      if (tagValue.length > 0 || tagValue > 0) {
        this.tags.push(tagValue)
        event.target.value = ''
      }
      
      this.inputWidth = {width: '1ch'}
      this.emitChangeEvent()
    },
    removeTag (index) {
      this.tags.splice(index, 1)
      this.emitChangeEvent()
    },
    removeLastTag(event) {
      if (event.target.value.length === 0) {
        this.removeTag(this.tags.length - 1)
      }
      this.emitChangeEvent()
    },
    emitChangeEvent() {
      this.$emit('change', this.tags)
    },
  }
}
</script>
<style scoped>
.tag-input {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  border: 2px solid rgb(209, 211, 214);
  min-height: 40px;
}
.tag-input input {
  padding-left: 10px;
  flex-grow: 1;
  flex-shrink: 1;
  max-width: 100%;
  font-size: 1rem;
}

.tag-input__tag {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  height: 28px;
  float: left;
  margin-right: 10px;
  background-color: #eee;
  margin: 4px 4px;
  line-height: 32px;
  padding: 0 4px;
  border-radius: 5px;
}

.tag-input__tag button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  padding: 0px;
  background-color: rgb(192,192,192);
  border: none;
  color: white;
  text-decoration: none;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  margin-left: 5px;
}
.tag-input__tag button:hover {
  background-color: rgb(146, 146, 146);
  cursor: pointer;
}

.tag-input__tag button span{
  width: 100%;
}
.tag-input__text {
  line-height: 100%;
  border: none;
  outline: none;
  background: none;
}


.close {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  height: 60%;
}
.close:before, .close:after {
  position: absolute;
  content: ' ';
  height: 100%;
  width: 2px;
  background-color: rgb(255, 255, 255);
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}

</style>